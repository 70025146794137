<template>
  <div>
    <inquiry-consultant-overview
     v-if="$hasPermissions('consultant permission')"
    />

    <inquiry-customer-overview
      v-if="$hasPermissions('customer permission')"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {},
  data: () => ({}),
  computed: {},
  watch: {
    $route() {
      this.inquiryDashboardIndex();
    }
  },
  created() {
    this.inquiryDashboardIndex();
  },
  methods: {
    ...mapActions({
      inquiryDashboardIndex: "inquiry/index"
    })
  }
};
</script>

<style scoped></style>